import { graphql, useStaticQuery } from 'gatsby';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { getBlogPagePosts, resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    paragraphsCounters: { firstRow: 1, secondRow: 1 },
});

export const useBlogFintech = (blogPosts, faqResult) => {
    const { industryLeadersPosts, posts } = getBlogPagePosts(
        blogPosts,
        'industry-leaders',
    );

    const descriptionLinksData = [
        {
            key: 'companiesLink',
            url: 'blog/fintech/fintech-companies-new-york',
            component: 'gatsby-link',
        },
        {
            key: 'edgeLink',
            url: 'blog/fintech/best-apps-for-investing',
            component: 'gatsby-link',
        },
    ];

    const subInfoLinksData = [
        {
            key: 'link',
            url: 'expertises/nft',
            component: 'gatsby-link',
        },
    ];

    const firstRowLinksData = [
        {
            key: 'bettermentLink',
            url: 'blog/wealth-management-solutions',
            component: 'gatsby-link',
        },
    ];

    const secondRowLinksData = [
        {
            key: 'insurtechLink',
            url: 'blog/fintech/top-insurtech-companies-uk',
            component: 'gatsby-link',
        },
        {
            key: 'openBankingLink',
            url: 'blog/open-api-banking',
            component: 'gatsby-link',
        },
    ];

    const images = useStaticQuery(
        graphql`
            query {
                firstRowImage: file(
                    relativePath: { eq: "fintech/fintech-mobile.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                secondRowImage: file(
                    relativePath: { eq: "fintech/fintech-desktop.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    const faqData = formatFaqData(faqResult);

    return {
        ...images,
        ...content,
        industryLeadersPosts,
        posts,
        hub: HUB_URLS.FINTECH,
        subInfoLinksData,
        descriptionLinksData,
        firstRowLinksData,
        secondRowLinksData,
        faqData,
    };
};
